<template>
  <div class="wrap padding-top-layout" @click="updateActionPosition">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <h1 class="wrap-title">{{ $t('b_list_submited.title_application_status') }}</h1>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div
              v-if="showTooltip"
              class="helper-icon tooltip"
              v-html="$t('b_list_submited.tooltip_application_status')"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="category-table main-table custom-table color-column-status-table list-submitted-data_table">
      <data-table
        :data-source="listSubmitGrid"
        :grid-columns="listSubmitColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="labelEmpty"
        :isResizeCustome="false"
        class="list-submitted-data_table"
        @changeFullScreen="onChangeFullScreen"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
    <div class="pull-down-action" :style="getStyleActionPullDown" @click="handleDeleteProcess()">
      <div class="pull-down-action-item">
        <img class="pull-down-action-item__icon" src="@/assets/icons/product/delete.svg" alt="" />
        <div class="pull-down-action-item__label">{{ $t('b_list_submited.button_detail') }}</div>
      </div>
    </div>
    <QuestionPopup
      :dialog="dialogDeleteProcess"
      :confirm="true"
      :message="$t('b_list_submited.popup_confirm_massge')"
      :confirmText="$t('b_list_submited.button_delete')"
      @close="dialogDeleteProcess = false"
      classes="register"
      @submit="handleAcceptDeleteProcess()"
    />
  </div>
</template>

<script>
import DataTable from '@/components/category/data-table';
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import LogHistoryPopup from '@/components/dialogs/history-popup.vue';
import { getProductsListWorkflowApi,getProductListLogHistory,getProductListCommentHistory,deleteProductData } from "@/api/product";
import { getCategoryName, getScopeName, getPeriodFromMonthYear } from '@/utils/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID } from '@/constants/registerData';
import { mapActions, mapState } from 'vuex';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import * as wjGrid from '@mescius/wijmo.grid';
import { getColorStatusById, getStatusNameById, getColorTextStatusById } from '@/utils/status';
import moment from 'moment';
import { getStartMonth } from '@/api/duration';
import QuestionPopup from '@/components/dialogs/question-popup';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { formatValue, $_helper_isNumberType, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma, addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';
import { getWidthOfDetailButton, getWidthOfLogHistory, getMinWidthOrganization } from '@/utils/multiLanguage'
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
export default {
  name: 'ListSubmitted',
  components: { DataTable, CommentLogPopup, LogHistoryPopup,QuestionPopup },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: ROUTES.PRODUCTS,
        },
        {
          text: this.$t('b_list_submited.label_application_status'),
          disabled: true,
          href: `${ROUTES.PRODUCTS}/${ROUTES.PRODUCTS_LIST_SUBMITTED}`,
        },
      ],
      listSubmitColumn: [],
      listSubmitGrid: null,
      flexGrid: null,
      listSubmitData: [],
      dialog: false,
      logContents: [],
      showTooltip: false,
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      processClick: {
        isShowAction: false,
      },
      duration: '',
      dialogDeleteProcess : false,
      listLayer :[],
      durationList: [],
      indexId: '',
      isEmptySource: true,
      labelEmpty: this.$tc('b_list_submited.label_empty'),
      selectedFilterColumn: null,
      startMonth: null,
    };
  },
  async mounted() {
    await this.updateBreadCrumb(this.breadCrumb);
    await getStartMonth(this.$store.state.userData.contractor).then(res => {
      this.startMonth = res.data.start_month;
    });
    await this.handleGetListSubmitData();
    document.addEventListener('scroll', this.updateActionPosition);
    const tableElement = document.querySelector('.category-table [wj-part=root]')
    tableElement.addEventListener('scroll', this.updateActionPosition);
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    isShowAction() {
      return this.processClick?.isShowAction;
    },
    getStyleActionPullDown() {
      const position = this.processClick;
      return `left: ${position.leftPosition - 8}px; top: ${position.topPosition + 30}px; display: ${
        this.isShowAction && this.processClick.checkStatus === 0 && this.processClick.is_owner === 1 ? 'flex' : 'none'
      }`;
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('newRegisterData', ['updateIsApproved']),
    ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),
    initGrid(grid) {
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }

          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false,
      );
      grid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === 'id') {
          const item = s.rows[e.row]._data;
          // hide button when status not draft || !owner 
          const isHideButtonDelete = item.status_id !== 0 || typeof item.is_owner !== 'number' || item.is_owner !== 1 
          if (isHideButtonDelete) {
            e.cell.innerHTML = '';
          }
        }
      })
      
      // add tooltip
      grid.onSelectionChanged(null);
      this.flexGrid = grid;
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      this.$router.push({ path: `/products/register?type=submit&id=${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId,
      };
      const commentList = await getProductListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map((commentHistory) => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.created_at),
          name: commentHistory.user.name,
          content: commentHistory.content,
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId,
      };
      const listHistoryChanged = await getProductListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map((listHistoryChanged) => {
        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.updated_at),
          name: listHistoryChanged.content,
          status: getStatusNameById(listHistoryChanged.status),
          color: getColorStatusById(listHistoryChanged.status),
        };
      });
    },
    getDurationById(durationID) {
      const selectedDuration = this.durationList.find(item => item.id === durationID)
      return `${moment.utc(selectedDuration.start_at).format("YYYY/MM")} - ${moment.utc(selectedDuration.end_at).format("YYYY/MM")}`
    },
    async handleGetListSubmitData() {
      // TODO: call API here
      this.actionUpdateIsLoadingTable(true)
      const payload = {
        action: 'listSubmit',
      };
      const listSubmitResponse = await getProductsListWorkflowApi(payload);
      if (listSubmitResponse.data.length > 0) {
        this.listSubmitData = listSubmitResponse.data.map((listSubmitItem) => {
          const product = listSubmitItem.product?.product_master
          const branchData = product?.branch_id ? product?.branch : product
          return {
            ...listSubmitItem,
            data_id: listSubmitItem.data_id,
            id: listSubmitItem.id.toString().split(',').join(''),
            title: listSubmitItem.title,
            status: getStatusNameById(listSubmitItem.status),
            contractor_name: listSubmitItem.contractor?.name,
            log_history: this.$t('b_list_submited.button_log_history'),
            scope: getScopeName(parseInt(listSubmitItem.scope)),
            category: getCategoryName(parseInt(listSubmitItem.scope), parseInt(listSubmitItem.category)),
            date_submit: formatDateTimeDataTable(listSubmitItem.submitted_at),
            month_year_registration: listSubmitItem.product?.year !== null && listSubmitItem.product?.month !== null ? `${listSubmitItem.product.year}/${String(listSubmitItem.product.month).padStart(2, '0')}` : '',
            last_modified: formatDateTimeDataTable(listSubmitItem.updated_at),
            iconComment: listSubmitItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
            created_at: formatDateTimeDataTable(listSubmitItem.submitted_at),
            return_at: formatDateTimeDataTable(listSubmitItem.return_at),
            approver: listSubmitItem.selected_approver?.name,
            approved_by: listSubmitItem.approved_by,
            scope_id: parseInt(listSubmitItem.scope),
            category_id: parseInt(listSubmitItem.category),
            duration_id: listSubmitItem.duration_id,
            products_name    : listSubmitItem.product?.product_master?.name,
            product_code     : listSubmitItem.product?.product_master?.code,
            approver_info: listSubmitItem.approved_by ? listSubmitItem.approver?.name : listSubmitItem.selected_approved?.name,
            organizational_division :branchData?.organizational_division !== null ? ORGANIZATIONAL_DATA[branchData?.organizational_division] : null,
            company_name     : branchData?.company_name || null,
            registration_period : listSubmitItem.product?.year !== null && listSubmitItem.product?.month !== null ? getPeriodFromMonthYear(moment, this.startMonth, listSubmitItem.product.month, listSubmitItem.product.year) : '',
            emission_total_product: listSubmitItem.product?.emission_total_product ? this.formatValueNumberFullData(listSubmitItem.product?.emission_total_product) : "0.00",
            emission_per_product : listSubmitItem.product?.emission_per_product ? this.formatValueNumberFullData(listSubmitItem.product?.emission_per_product) : "-",
            business_name: branchData?.business_name || null,
            country: branchData?.country || null,
            layer_3 : branchData?.layer_3 || null,
            layer_4 : branchData?.layer_4 || null,
            layer_5 : branchData?.layer_5 || null,
            layer_6 : branchData?.layer_6 || null,
            status_id: listSubmitItem.status,
            colorStatus: getColorTextStatusById(listSubmitItem.status)
          };
        });
      }
      this.listLayer = listSubmitResponse.layer;
      this.defineTable();
      this.actionUpdateIsLoadingTable(false)
    },
    initialView() {
      if (this.listSubmitGrid) {
        this.listSubmitData = this.listSubmitGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      const formatItems = ['emission_total_product', 'emission_per_product'];
      this.listSubmitGrid = new CollectionView([...this.listSubmitData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        }
      });
      this.flexGrid.columnGroups = this.getListSubmitColumn();
      this.listSubmitGrid.currentItem = null;
      this.flexGrid.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }
      });
      this.markUnreadRows();
      this.isEmptySource = this.listSubmitGrid?.items?.length <= 0;
      setMinMaxSizeColumns(this.flexGrid, this.listSubmitData);
    },
    markUnreadRows() {
      this.flexGrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.listSubmitGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread =
            currentItem !== undefined && currentItem['unread_flg'] !== undefined ? currentItem['unread_flg'] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add('wj-has-unread-mark');
            }
          }
        }
      });
    },
    addBlankItemsToView(count) {
      const lastClientId = this.listSubmitGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.listSubmitGrid.addNew(this.blankData(lastClientId + index));
      }

      this.listSubmitGrid.commitNew();
      this.listSubmitGrid.clearChanges();
    },
    getListSubmitColumn() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      }
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly  : true,
          cssClassAll : "submitted-color",
          multiLine   : true,
        };
      });
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'data_id',
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: 'template-detail-header submitted-color',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_list_submited.button_detail'),
            click: (event, context) => this.onIdClicking(context),
          }),
        },
        {
          header      : " ",
          binding     : "id",
          minWidth    : 32,
          maxWidth    : 32,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "btn-db hide-filter product-delete-btn",
          cssClass : 'disable-btn',
          multiLine   : true,
          wordWrap    : true,
          align  : "center",
          cellTemplate: CellMaker.makeButton({
            text : "<span id=\"${text}\" class=\"${(item.status_id !== 0 || typeof item.is_owner !== 'number' || item.is_owner != 1) ? 'data-table-action' : ''}\">...</span>",
            click: (event,context) => this.handleActionProcess(context,event)
          })
        },
        {
          header: this.$t('b_list_submited.table_status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
          cellTemplate: (ctx) => (`<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`),
        },
        {
          header: ' ',
          binding: 'iconComment',
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: 'btn-db',
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context),
          }),
          cssClassAll: 'approval-cell_comment hide-filter comment-icon',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_list_submited.table_products_name'),
          binding: 'products_name',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_product_code'),
          binding: 'product_code',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_created_at'),
          binding: 'created_at',
          minWidth: 165,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_return_at'),
          binding: 'return_at',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_return_at')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_approver_info'),
          binding: 'approver_info',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_approver_info')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('b_list_submited.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getMinWidthOrganization(),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
              cssClassAll: 'submitted-color'
            },
            {
              header: this.$t('b_list_submited.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('b_list_submited.table_company_name')) + 5,
              width: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
              cssClassAll: 'submitted-color'
            },
            ...layers
          ],
        },
        {
          header: this.$t('b_list_submited.table_registration_period'),
          binding: 'registration_period',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_registration_period')),
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_month_year_registration'),
          binding: 'month_year_registration',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_month_year_registration')),
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color'
        },
        {
          header: this.$t('b_list_submited.table_exhaust_amount_(t-co2)'),
          align: 'center',
          cssClassAll: 'emission',
          columns: [
            {
              header      : this.$t('b_list_submited.table_emission_total_product'),
              binding     : "emission_total_product",
              minWidth    : getWidthByTextContent(this.$t('b_list_submited.table_emission_total_product')) + 5,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
              cssClassAll: 'emission color-text',
              align: 'right',
            },
            {
              header      : this.$t('b_list_submited.table_emission_per_product'),
              binding     : "emission_per_product",
              minWidth    : getWidthByTextContent(this.$t('b_list_submited.table_emission_per_product')) + 5,
              maxWidth    : 400,
              allowSorting: false,
              isRequired  : false,
              isReadOnly  : true,
              wordWrap    : true,
              cssClassAll: 'emission color-text',
              align: 'right',
            },
          ],
        },
        {
          header      : this.$t('b_list_submited.table_last_modified'),
          binding     : "last_modified",
          minWidth    : getWidthByTextContent(this.$t('b_list_submited.table_last_modified')),
          maxWidth    : 300,
          allowSorting: false,
          isRequired  : false,
          isReadOnly  : true,
          cssClassAll : "btn-db submitted-color",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header: this.$t('list_submited.button_log_history'),
          binding: 'log_history',
          minWidth: getWidthOfLogHistory(),
          width: '*',
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'submit-log show-log-btn',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_list_submited.button_log_history'),
            click: (e, ctx) => this.onHistoryClicking(ctx),
          }),
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    defineTable() {
      this.listSubmitColumn = this.getListSubmitColumn();
      this.initialView();
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    handleActionProcess(context, event) {
      event.stopPropagation();
      const id = context.row.dataItem.id > 999 ? this.formatValueNumber(context.row.dataItem.id) : context.row.dataItem.id
      const element = document.getElementById(id).getBoundingClientRect()
      const leftPosition = element.left;
      const topPosition = element.top;
      this.processClick = { ...context.row.dataItem, leftPosition, topPosition, isShowAction: true };
      this.indexId = context.row.dataItem.id
    },
    updateActionPosition() {
      this.processClick.isShowAction = false;
    },
    handleDeleteProcess() {
      this.dialogDeleteProcess = true
    },
    formatValueNumber(number) {
      return addThoundSandComma(number)
      // return formatValue(number).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
    },
    formatValueNumberFullData(number) {
      return addThoundSandCommaWithTwoDigit(formatBigNumber(number, 50).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    handleAcceptDeleteProcess() {
      this.dialogDeleteProcess = false
      deleteProductData(this.indexId).then(() => {
        this.getProductsListData()
      })
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
  },
  beforeDestroy() {
    document.addEventListener('scroll', this.updateActionPosition);
    const tableElement = document.querySelector('.category-table [wj-part=root]')
    tableElement.addEventListener('scroll', this.updateActionPosition);
  },
};
</script>

<style lang="scss">
.list-submitted-data_table {
  .template-detail-header {
    padding: 2px 4px !important;

    .wj-btn {
      display: none;
    }
  }

  .wj-flexgrid .wj-cell[aria-readonly]:not(.emission,.wj-state-multi-selected) {
    background: #f7f7f2 !important;  
    color: #404d50 !important;
  }
  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].submit-log {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].wj-state-active:not(.emission,.wj-state-multi-selected) {
    background: $monoOffWhite !important;
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #404d50;
    // font-family: 'Source Han Sans JP';
    padding: 0;
  }

  .wj-cell {
    .wj-cell-maker {
      justify-content: center;
    }

    .wj-cell-maker:hover {
      color: #ffffff;
      background: #0072a3;
    }
  }

  .template-detail-header {
    .wj-cell-maker {
      // margin-top: 2px;
      font-style: normal;
      font-family: 'Source Han Sans';
      width: 100%;
      color: $goldMid !important;
      position: unset !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid #a4a184;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .hide-filter {
    .wj-btn {
      display: none;
    }
  }

  .submit-log.wj-cell {
    .wj-btn {
      display: none;
    }

    .wj-cell-maker {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 6px 3px;
      background: #e4e6e7;
      font-family: 'Source Han Sans';
      border-radius: 4px;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #404d50 !important;
      min-width: 64px;
      min-height: 24px;
      position: unset !important;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;
      button {
        color: #ffffff;
      }
    }
  }

  .approval-pending {
    color: #0072a3;
  }

  .approval-send-back {
    color: $redMid;
  }

  .approval-complete {
    color: #99a6a9;
  }
  // .wj-elem-collapse {
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   left: 8px;
  //   top: 6px;
  //   background: rgba(121, 134, 134, 0.12);
  //   border-radius: 6px;
  //   &.wj-glyph-minus {
  //     color: $monoDark;
  //     border-top: 1px solid;
  //   }
  // }
  .line-height_text {
    .wj-cell-maker {
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      text-align: center;
      color: $monoBlack;
    }
  }
  .wj-flexgrid{
    .wj-cell {
      &.disable-btn {
        .wj-cell-maker {
          padding: 0 !important;
          overflow: hidden;
          line-height: 16px;
          color: $monoBlack;
          .data-table-action {
            cursor: text !important;
            background-color: #c7d0d0 !important;
            width: 100%;
            height: 100%;
            &:hover {
              color: $monoBlack !important;
            }
          }
          &:hover {
            color: $monoWhite !important;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}

.wrap {
  width: 100%;
  height: 100%;

  .wrap__block__header__content {
    display: flex;
    align-items: center;
    // tooltip icon
    .helper-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;

      img {
        z-index: 1;
      }

      &.tooltip {
        display: block;
        justify-content: left;
        background: $monoWhite;
        border-radius: 4px;
        color: $monoBlack;
        margin: 0 auto;
        font-size: 15px;
        padding: 10px;
        height: unset;
        // width: 315px;
        max-width: max-content;
        // position: absolute;
        right: unset;
        left: 0px;
        bottom: unset;
        z-index: 9999;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        position: fixed;
        width: 100%;
      }
    }
  }

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {
    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 40px;
    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      @media (max-width: 768px) {
        padding-top: 40px;
      }

      &__content {
        flex-direction: row;
      }

      span,
      .wrap-title {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }
  }
  .pull-down-action {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    min-width: 89px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    cursor: pointer;
    &.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      min-width: 89px;
      height: 40px;
      &__label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }
  .wrap__block {
    margin-left: 40px;
  }
  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
