import { STATUS } from '@/constants/status';
import i18n from '@/lang/i18n';

export const getStatusNameById = (statusID) => {
  const status = STATUS.find(status => status.id === statusID);
  return i18n.t(status?.name)
}

export const getStatusIdByName = (statusName) => {
  const status = STATUS.find(status => status.name === statusName);
  return status?.id
}
export const getColorStatusById = (statusID) => {
  const status = STATUS.find(status => status.id === statusID);
  return status?.color
}
export const getColorTextStatusById = (statusID) => {
  const status = STATUS.find(status => status.id === statusID);
  return status?.color_text
}
